import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import TableHeaderWithInfoCircle from '@/components/common/molecules/tableHeaderWithInfoCircle';
import CampProgressCard from '@/components/common/atoms/CampProgressCard';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import { Record } from '@/components/TabContent/EduCamp/Private/UserList';
import { Button, Col, ConfigProvider, Row } from 'antd';
import { StyledUserCountContainer } from '@/components/UserList/UserList';
import Text from '@/components/common/atoms/Text';
import theme, { mixin } from '@/styles/theme';
import { numberToLocalString } from '@/utils/number';
import { StyledBasicTable, StyledCompletionState } from '@/components/common/styledComponents';
import styled from 'styled-components';
import { downloadCampCouponApplicantToExcel } from '@/api/report/eduCampCoupon';
import dayjs from 'dayjs';
import useContractId from '@/hooks/useContractId';
import { downloadExcelFile } from '@/utils/download';
import usePartnerName from '@/hooks/usePartnerName';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { useDispatch } from 'react-redux';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { PRODUCTS } from '@/utils/constants/products';
import { DATE_FORMAT, formatDate, getIsFreeSchedule } from '@/utils/date';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import { useEduCampCouponQuery } from '@/queries/report/eduCampCoupon/useEduCampCouponQuery';
import { EDU_CAMP_APPLICANT_LIST_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampCommon';
import { openCertificate } from '@/utils/certificate';
import useWaitCursor from '@/hooks/useWaitCursor';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

interface RecordDate {
	start_date?: string;
	end_date?: string;
	paid_at?: string;
}

interface RecordProgress extends RecordDate {
	no?: number;
	assignments_detail?: (number | undefined)[];
	attendances_detail?: (number | undefined)[];
	start_date?: string;
	camp_week?: number;
	camp_weeks?: CampWeekData[];
}

interface CampWeekData {
	camp_no: number;
	object: string;
	week: number;
	start_at: string;
	end_at: string;
}

const DetailLinkBox = styled.div`
	${mixin.flexBox()};
	${mixin.flexDirection('column')}
`;

const StyledDateString = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
`;

const StyledTitleBox = styled.div`
	display: flex;
	align-items: flex-start;
	${mixin.ellipsis(2)}
`;

const StyledText = styled.span`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
`;

const StyledSemiBoldText = styled(StyledText)`
	font-weight: 600;
`;

const StyledTableWrapper = styled.div`
	.coupon-code {
		padding: 12px;
	}
`;

const EduCampCouponUserList = () => {
	const contractId = useContractId();
	const { data, isLoading, isFetching } = useEduCampCouponQuery(contractId);
	const couponApplicantData = data?.data ?? EDU_CAMP_APPLICANT_LIST_INITIAL_DATA;
	const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);
	const partnerName = usePartnerName();
	const dispatch = useDispatch();
	const params = useParams();
	const stateInfoTemplate = `시작전 : 아직 캠프가 시작하지 않았습니다.\n\n진행중 : 캠프가 진행 중입니다.\n\n수료 심사중 : 캠프가 종료되어 수료 기준 충족 여부를 심사하고 있습니다. (심사까지 최대 7일이 소요됩니다.)\n\n수료 : 캠프 종료 후 수료 기준을 충족하여 수료증을 발급할 수 있습니다.\n\n미수료 : 캠프가 종료되었으나 수료 기준을 충족하지 못했습니다.\n\n중도 취소 : 캠프 진행 도중, 신청자가 수강을 취소하였습니다. \n\n취소 : 캠프 수강 전, 신청자가 수강을 취소하였습니다.`;
	const infoTooltipScript = '수료증은 신청자의 상태가 수료인 경우에만 조회할 수 있습니다.';
	const progressInfoTemplate = `파란색 : 완료 상태입니다.\n\n빨간색 : 미완료 상태입니다.\n\n노란색 : 현재 과제 제출 기간이며, 아직 미제출 상태입니다.\n\n회색 : 아직 진행되지 않은 주차입니다.`;
	const freeScheduleCampInfo =
		'[자율일정?]\n' +
		'90일 이내에 VOD와 과제를 스스\n로 진행하는 직무부트캠프 입니다.\n (출석 1회, 과제 4회로 구성)';

	const getDateString = (startDate?: string, endDate?: string, paidAt?: string) => {
		if (!startDate || !endDate || !paidAt) return;
		const isFreeScheduleCamp = getIsFreeSchedule(startDate, endDate);
		if (!isFreeScheduleCamp)
			return `${formatDate(startDate, DATE_FORMAT.FULL_DATE)} ~ ${formatDate(endDate, DATE_FORMAT.FULL_DATE)}`;
		return `${formatDate(paidAt, DATE_FORMAT.FULL_DATE)} ~ ${dayjs(paidAt)
			.add(90, 'day')
			.format(DATE_FORMAT.FULL_DATE)}`;
	};

	const getEduAssignmentState = (record: RecordProgress) => {
		const campWeeks = record?.camp_weeks ?? [];
		if (!campWeeks.length) {
			return [0, 0, 0, 0, 0];
		}
		const resultMap: { [key: number]: { end_at?: string; start_at?: string } } = {};
		campWeeks.forEach(weekData => {
			const { week, start_at, object } = weekData;
			if (!resultMap[week]) {
				resultMap[week] = {};
			}

			if (!resultMap[week].start_at && object === 'main') {
				resultMap[week].start_at = start_at;
			} else {
				resultMap[week].end_at = start_at;
			}
		});
		const campWeekRange = Object.values(resultMap);
		return record.assignments_detail?.map((assignment, index) => {
			const isLastItem = index - 1 === record.assignments_detail?.length;
			const isCurrentAssignment = dayjs()
				.tz('Asia/seoul')
				.isBetween(dayjs(campWeekRange[index].start_at), dayjs(campWeekRange[index].end_at), 'minute', '[)');
			if (!isLastItem && isCurrentAssignment && assignment === -1) {
				return 2;
			}
			return assignment;
		});
	};

	const onClickCertificate = useWaitCursor(openCertificate);

	const list = [
		{
			title: '쿠폰 번호',
			dataIndex: 'coupon_code',
			key: 'coupon_code',
			width: '11.05%',
			className: 'coupon-code',
			align: 'center' as const,
			render: (coupon: string) => <StyledText>{coupon}</StyledText>,
		},
		{
			title: '이름',
			dataIndex: 'user_name',
			key: 'user_name',
			width: '7.89%',
			align: 'center' as const,
		},
		{
			title: '신청일',
			dataIndex: 'paid_at',
			key: 'paid_at',
			width: '10.45%',
			align: 'center' as const,
			render: (paidAt: string) => (
				<StyledDateString>{formatDate(paidAt, DATE_FORMAT.FULL_DATE)}</StyledDateString>
			),
		},
		{
			title: '직무',
			dataIndex: 'mid_category',
			key: 'mid_category',
			width: '7.89%',
			align: 'center' as const,
		},
		{
			title: () => (
				<TableHeaderWithInfoCircle
					title={'신청 캠프'}
					tooltipContent={freeScheduleCampInfo}
					tooltipPlacement={'bottom'}
				/>
			),
			dataIndex: 'camp_title',
			key: 'applied_camp',
			width: '23.67%',
			align: 'left' as const,
			render: (title: string, record: RecordDate) => (
				<StyledTitleBox>
					{getIsFreeSchedule(record.start_date, record.end_date) ? (
						<>
							<StyledText>
								<StyledSemiBoldText>[자율일정]</StyledSemiBoldText>
								&nbsp;{title}
							</StyledText>
						</>
					) : (
						<StyledDateString>{title}</StyledDateString>
					)}
				</StyledTitleBox>
			),
		},
		{
			title: '캠프 진행일',
			dataIndex: 'date',
			key: 'date',
			width: '11.64%',
			align: 'center' as const,
			render: (_: string, record: RecordDate) => (
				<StyledDateString>{getDateString(record.start_date, record.end_date, record.paid_at)}</StyledDateString>
			),
		},
		{
			title: () => (
				<TableHeaderWithInfoCircle
					title={'수강 현황'}
					tooltipContent={progressInfoTemplate}
					tooltipPlacement={'bottom'}
				/>
			),
			dataIndex: 'progress',
			key: 'progress',
			width: '11.14%',
			align: 'center' as const,
			render: (_: string, record: RecordProgress) => (
				<CampProgressCard
					isFreeSchedule={!!getIsFreeSchedule(record.start_date, record.end_date)}
					assignment={getEduAssignmentState(record)}
					attendance={record.attendances_detail}
					campWeek={record.camp_week}
					key={`progress-card-${record.no}`}
					isCoupon
				/>
			),
		},
		{
			title: () => (
				<TableHeaderWithInfoCircle
					title={'내역조회'}
					tooltipContent={infoTooltipScript}
					tooltipPlacement={'bottom'}
				/>
			),
			dataIndex: 'action',
			key: 'action',
			width: '9.07%',
			align: 'center' as const,
			render: (_: string, record: Record) => (
				<>
					{!!record.user_name && (
						<DetailLinkBox>
							<Button
								disabled={!record.complete}
								type={'link'}
								onClick={() => onClickCertificate(record.applicant_no)}
							>
								수료증
							</Button>
							<Link
								to={dynamicRoutePath(
									`${ROUTER_PATH.REPORT.EDU_CAMP_COUPON.USER_LIST}/${record.applicant_no}`,
									params,
								)}
							>
								수강 현황
							</Link>
						</DetailLinkBox>
					)}
				</>
			),
		},
		{
			title: () => {
				return (
					<TableHeaderWithInfoCircle
						title={'상태'}
						tooltipContent={stateInfoTemplate}
						tooltipPlacement={'bottom'}
					/>
				);
			},
			dataIndex: 'state_detail',
			key: 'state_detail',
			width: '7.59%',
			align: 'center' as const,
			tooltipContent: stateInfoTemplate,
			render: (state: string) => <StyledCompletionState completion={state}>{state}</StyledCompletionState>,
		},
	];

	const downloadCampCouponApplicantExcel = async () => {
		try {
			setIsDownloadLoading(true);
			const { data } = await downloadCampCouponApplicantToExcel({ contractNo: contractId });
			downloadExcelFile(data, `${partnerName}-${PRODUCTS.EDU_CAMP_COUPON}-신청자 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setIsDownloadLoading(false);
		}
	};

	// 전체 다운로드 추가시 사용 예정
	// const onMenuClick: MenuProps['onClick'] = e => {
	// 	const download = e.key;
	// 	switch (download) {
	// 		case 'applicant':
	// 			return downloadCampCouponApplicantExcel();
	// 		default:
	// 			return alert('준비중입니다');
	// 	}
	// };
	//
	// const menu = (
	// 	<Menu
	// 		onClick={onMenuClick}
	// 		items={[
	// 			{
	// 				key: 'applicant',
	// 				label: '신청 명단 다운로드',
	// 			},
	// 			{
	// 				key: 'certification',
	// 				label: '수료증 다운로드',
	// 			},
	// 			{
	// 				key: 'assignment',
	// 				label: '과제 다운로드',
	// 			},
	// 		]}
	// 	/>
	// );

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('캠프 신청자 현황')));
	}, []);

	return (
		<>
			<Row>
				<Col span={24}>
					<StyledUserCountContainer>
						<Text color={theme['gray-6']}>
							총 신청자 수 :&nbsp;
							<Text color={theme['blue-7']}>{numberToLocalString(couponApplicantData.count)}</Text>명{' '}
							{isFetching ? '(새로고침 중)' : ''}
						</Text>
						{/*임시 로직 전체 다운로드 추가시 교체 예정*/}
						<DownloadButton
							loading={isLoading || isDownloadLoading}
							onClick={downloadCampCouponApplicantExcel}
						>
							신청자 명단 다운로드
						</DownloadButton>
					</StyledUserCountContainer>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<ConfigProvider renderEmpty={() => <EmptyTableSection />}>
						<StyledTableWrapper>
							<StyledBasicTable
								dataSource={couponApplicantData.list}
								columns={list}
								loading={isLoading}
								pagination={false}
								isLoading={isLoading}
								rowKey={'applicant_no'}
							/>
						</StyledTableWrapper>
					</ConfigProvider>
				</Col>
			</Row>
		</>
	);
};

export default EduCampCouponUserList;
